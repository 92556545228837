<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { PhAt, PhPassword, PhStairs } from '@phosphor-icons/vue'
import { Button, FormField, Logo, PromptPanel } from '@/components'
import { useAuth } from '@/plugins/auth'

const router = useRouter()
const route = useRoute()

const { login: authLogin } = useAuth()

const email = ref('')
const password = ref('')

const login = async () => {
  await authLogin(email.value, password.value)

  // if supplied, redirect to the previous page after login
  const redirect = route.query.redirect
  if (redirect) {
    router.replace(redirect as string)
  } else {
    router.replace({ name: 'home' })
  }
}
</script>

<template>
  <UseHead>
    <title>{{ $t('pages.login.title') }}</title>
  </UseHead>

  <PromptPanel class="w-[min(18rem,90vw)]">
    <template #header>
      <div class="flex aspect-square w-full items-center justify-center">
        <Logo size="xl" />
      </div>

      <p class="text-lavender-800">Welcome back!</p>
    </template>

    <form
      id="login"
      class="flex flex-col items-stretch gap-3 pb-3 text-left"
      @submit.prevent="login"
    >
      <FormField>
        <template #icon><PhAt weight="duotone" /></template>

        <template #label>
          <label for="email" class="sr-only">{{
            $t('forms.login.email')
          }}</label>
        </template>

        <input
          id="email"
          v-model="email"
          type="email"
          :placeholder="$t('forms.login.emailPlaceholder')"
          required
        />
      </FormField>

      <FormField>
        <template #icon><PhPassword weight="duotone" /></template>

        <template #label>
          <label for="password" class="sr-only">{{
            $t('forms.login.password')
          }}</label>
        </template>

        <input
          id="password"
          v-model="password"
          type="password"
          :placeholder="$t('forms.login.passwordPlaceholder')"
          required
        />
      </FormField>
    </form>

    <template #actions>
      <Button volume="loud" form="login" type="submit" class="w-full">
        <PhStairs weight="duotone" />
        {{ $t('actions.login') }}
      </Button>
    </template>
  </PromptPanel>
</template>
