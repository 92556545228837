import * as Yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { FormSchema } from '@/components/DForm'
import { TextareaUpload } from '@/components/TextareaUpload'
import { Candidate } from '@@/types'

export const candidateFormSchema: FormSchema<Candidate> = {
  rules: toTypedSchema(
    Yup.object({
      resume: Yup.string(),
      coverLetter: Yup.string().notRequired(),
    })
  ),

  fields: [
    {
      name: 'resume',
      label: 'Resume',
      as: TextareaUpload,
      attrs: {
        rows: 10,
      },
    },
    {
      name: 'coverLetter',
      label: 'Cover letter',
      as: TextareaUpload,
      attrs: {
        rows: 10,
      },
    },
  ],
}
