import { Plugin } from 'vue'
import { default as axios, type CreateAxiosDefaults } from 'axios'
import { useAxios, UseAxiosOptions } from '@vueuse/integrations/useAxios'

export interface ApiOptions {
  defaults: CreateAxiosDefaults
  interceptors?: {
    request?: Array<Parameters<typeof axios.interceptors.request.use>[0]>
    response?: Array<Parameters<typeof axios.interceptors.response.use>[0]>
  }
}

export const key = Symbol('api')

let client: ReturnType<typeof axios.create> | null = null

export const getApi = () => {
  if (!client) {
    throw new Error('getApi must be called after the api plugin is installed')
  }
  return client
}

export const createApi = (): Plugin => ({
  install(app, options: ApiOptions) {
    client = axios.create(options.defaults)

    if (options.interceptors?.request?.length) {
      options.interceptors.request.forEach((interceptor) => {
        client?.interceptors.request.use(interceptor)
      })
    }

    if (options.interceptors?.response?.length) {
      options.interceptors.response.forEach((interceptor) => {
        client?.interceptors.response.use(interceptor)
      })
    }

    app.provide(key, client)
  },
})

export function useApi<T>(
  url: string,
  config: Parameters<typeof useAxios>[0] = {},
  options?: UseAxiosOptions
) {
  if (!client) {
    throw new Error('useApi must be called after the api plugin is installed')
  }

  return useAxios<T>(url, config, client, options)
}
