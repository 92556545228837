<script setup lang="ts">
import { useDark } from '@vueuse/core'
import { BasicLayout } from '@/layouts'

useDark()
</script>

<template>
  <component :is="$route.meta.layout || BasicLayout">
    <suspense>
      <router-view v-slot="{ Component, route: pageRoute }">
        <component :is="Component" :key="pageRoute.path" />
      </router-view>
    </suspense>
  </component>
</template>
