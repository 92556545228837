<script setup lang="ts">
import { ref } from 'vue'
import * as pdfjs from 'pdfjs-dist'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

const modelValue = defineModel<string>()

defineProps({
  disabled: Boolean,
})

defineOptions({
  inheritAttrs: false,
})

const loading = ref(false)

const parseFile = async (event: Event) => {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]

  if (!file) return

  loading.value = true

  const reader = new FileReader()

  reader.onload = async (event) => {
    const buffer = event.target?.result as ArrayBuffer
    const pdf = await pdfjs.getDocument(buffer).promise
    console.log('page count', pdf.numPages)

    let text = ''

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i)
      const textTokens = await page.getTextContent()
      text += textTokens.items
        .map((token) => {
          if (!('str' in token)) return ''

          return token.str.length ? token.str : '\n'
        })
        .join('')
    }

    console.log(text)

    modelValue.value = text

    loading.value = false
  }

  reader.readAsArrayBuffer(file)
}
</script>

<template>
  <div>
    <textarea v-model="modelValue" v-bind="$attrs" :disabled="disabled" />
    <input type="file" :disabled="disabled" @change="parseFile($event)" />
  </div>
</template>
