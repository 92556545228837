<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Alert, Button, DForm, DGroup, Panel } from '@/components'
import { rubricFormSchema, criterionFormSchema } from '@/forms'
import { getApi, useApi } from '@/plugins/api'
import { Criterion, Rubric } from '@@/types'
import { PhRowsPlusBottom, PhCheck, PhTrash } from '@phosphor-icons/vue'

const route = useRoute()
const router = useRouter()

const api = getApi()

const id = route.params.id

const criteria = ref<Criterion[]>([])

const {
  data: rubric,
  isLoading,
  execute,
} = useApi<Rubric>(`/rubrics/${id}`, {}, { immediate: false })

if (id) {
  const res = await execute()

  if (res.data.value?.criteria) {
    criteria.value = res.data.value.criteria
  }
}

const totalWeight = computed(() => {
  return criteria.value.reduce((acc, c) => acc + Number(c.weight), 0)
})

const save = async (data: Partial<Rubric>) => {
  const stripped: Partial<Rubric> = {
    name: data.name,
    criteria: criteria.value.map((c) => ({
      ...c,
      weight: Number(c.weight),
    })),
  }

  if (id) {
    await api.put(`/rubrics/${id}`, stripped)
  } else {
    await api.post('/rubrics', stripped)
  }

  router.push({ name: 'rubrics' })
}

const addCriterion = () => {
  criteria.value.push({
    name: '',
    slug: '',
    weight: 0,
    description: '',
  })
}

const updateCriterion = (index: number, data: Partial<Criterion>) => {
  criteria.value[index] = data as Criterion
}

const removeCriterion = (index: number) => {
  criteria.value.splice(index, 1)
}
</script>

<template>
  <useHead>
    <title>{{ id ? rubric?.name : $t('pages.rubricsCreate.title') }}</title>
  </useHead>

  <PageLayout>
    <template #sticky-header>
      <PageHeader
        :title="id ? rubric?.name : $t('pages.rubricsCreate.title')"
        :breadcrumbs="[{ text: $t('nav.rubrics'), to: { name: 'rubrics' } }]"
        :loading="isLoading"
      />
    </template>

    <section>
      <DForm
        form-id="rubric-form"
        :schema="rubricFormSchema"
        :initial-values="rubric"
        :loading="isLoading"
        class="mb-10"
        submit-text=""
        cancel-text=""
        @submit="save"
        @cancel="router.push({ name: 'rubrics' })"
      />
    </section>

    <section>
      <header class="mb-5">
        <h2 class="text-base font-semibold">
          {{ $t('pages.rubricsEdit.criteriaTitle') }}
        </h2>
      </header>

      <Alert v-if="totalWeight !== 100" variant="warning" class="mb-5">
        {{ $t('messages.criteriaWeights', { total: totalWeight }) }}
      </Alert>

      <Panel
        v-for="(criterion, i) in criteria"
        :key="i"
        padding="lg"
        class="mb-2"
        header-class="flex items-center gap-5"
      >
        <template #header>
          <div class="mr-auto font-semibold">
            {{ criterion.name }}
          </div>

          <Button size="sm" destructive @click="removeCriterion(i)">
            <PhTrash weight="duotone" />
            {{ $t('actions.delete') }}
          </Button>
        </template>

        <DForm
          v-slot="{ fields }"
          :schema="criterionFormSchema"
          :initial-values="criterion"
          :loading="isLoading"
          submit-text=""
          cancel-text=""
          class="items-start lg:grid-cols-2"
          @update="updateCriterion(i, $event)"
        >
          <DGroup
            :fields="fields"
            :include="['name', 'slug', 'weight']"
            class="grid-items-start grid gap-y-5"
          />
          <DGroup :fields="fields" :include="['description']" />
        </DForm>
      </Panel>

      <Button @click="addCriterion">
        <PhRowsPlusBottom weight="duotone" />
        {{ $t('actions.addModel', { model: $t('models.criterion') }) }}
      </Button>
    </section>

    <template #sticky-footer>
      <Button volume="loud" form="rubric-form" type="submit" class="w-32">
        <PhCheck weight="bold" />
        {{ $t('actions.save') }}
      </Button>
    </template>
  </PageLayout>
</template>
