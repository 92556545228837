<script setup lang="ts"></script>

<template>
  <div
    class="flex flex-col gap-4 rounded-3xl bg-lavender-50 p-8 text-center elevation-3 elevation-lavender-400/20"
  >
    <header v-if="$slots.header" class="flex flex-col items-center">
      <slot name="header" />
    </header>

    <div v-if="$slots.default">
      <slot />
    </div>

    <div v-if="$slots.actions" class="flex flex-col gap-2">
      <slot name="actions" />
    </div>
  </div>
</template>
