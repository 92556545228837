import * as Yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { FormSchema } from '@/components/DForm'
import { Rubric } from '@@/types'

export const rubricFormSchema: FormSchema<Rubric> = {
  rules: toTypedSchema(
    Yup.object({
      name: Yup.string().required(),
    })
  ),

  fields: [
    {
      name: 'name',
      label: 'Name',
    },
  ],
}
