import * as Yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { FormSchema } from '@/components/DForm'
import { Criterion } from '@@/types'

export const criterionFormSchema: FormSchema<Criterion> = {
  rules: toTypedSchema(
    Yup.object({
      name: Yup.string().required(),
      slug: Yup.string()
        .matches(/^[a-z0-9-]+$/)
        .required(),
      weight: Yup.string().required(),
      description: Yup.string().required(),
    })
  ),

  fields: [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'slug',
      label: 'Slug',
      help: 'lowercase letters, numbers, and hyphens only',
    },
    {
      name: 'weight',
      label: 'Weight',
      help: 'Out of 100',
      type: 'number',
      attrs: {
        min: 0,
        max: 100,
      },
    },
    {
      name: 'description',
      label: 'Description',
      as: 'textarea',
      attrs: {
        rows: 5,
      },
    },
  ],
}
