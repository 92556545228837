<script setup lang="ts">
import { useDark, useToggle } from '@vueuse/core'
import { useAuth } from '@/plugins/auth'
import { Logo } from '@/components'
import AppMenuItem from './AppMenuItem.vue'
import {
  PhBriefcase,
  PhSliders,
  PhSun,
  PhMoonStars,
  PhStairs,
} from '@phosphor-icons/vue'

const { isLoggedIn } = useAuth()

const isDark = useDark()
const toggleDark = useToggle(isDark)
</script>

<template>
  <aside
    class="scrollbar-hidden flex flex-col items-center justify-between gap-4 overflow-auto p-4"
  >
    <router-link :to="{ name: 'home' }">
      <Logo />
    </router-link>

    <menu
      class="flex w-full max-w-56 flex-col gap-1 rounded-xl bg-lavender-100/20 p-2 backdrop-blur-[3px] thickness-1 thickness-lavender-300/60"
    >
      <slot name="nav-menu">
        <AppMenuItem :to="{ name: 'jobs' }" :count="0">
          <PhBriefcase weight="duotone" />
          {{ $t('nav.jobs') }}
        </AppMenuItem>

        <AppMenuItem :to="{ name: 'rubrics' }">
          <PhSliders weight="duotone" />
          {{ $t('nav.rubrics') }}
        </AppMenuItem>
      </slot>
    </menu>

    <menu
      class="flex w-full max-w-56 flex-col gap-1 rounded-xl bg-lavender-100/20 p-2 backdrop-blur-[3px] thickness-1 thickness-lavender-300/60"
    >
      <slot name="user-menu">
        <AppMenuItem @click="toggleDark()">
          <PhSun v-if="isDark" weight="duotone" />
          <PhMoonStars v-else weight="duotone" />
          {{ isDark ? 'Light' : 'Dark' }}
        </AppMenuItem>

        <AppMenuItem :to="{ name: !isLoggedIn ? 'login' : 'logout' }">
          <PhStairs weight="duotone" />
          {{ !isLoggedIn ? $t('nav.login') : $t('nav.logout') }}
        </AppMenuItem>
      </slot>
    </menu>
  </aside>
</template>
