<script setup lang="ts">
import { Ref, ref, watch } from 'vue'
import { MaybeElement, useFocus } from '@vueuse/core'
import {
  PhCalendarDots,
  PhCaretUpDown,
  PhEye,
  PhEyeClosed,
} from '@phosphor-icons/vue'
import { useFirstInput } from './useFirstInput'

const root = ref()
const rootFocus = useFocus(root).focused
const inputWrapper = ref()
const { input, type } = useFirstInput(inputWrapper)
const inputFocus = useFocus(input as Ref<MaybeElement>).focused
const showPassword = ref(false)

watch(rootFocus, (newVal) => {
  if (newVal) {
    inputFocus.value = true
    input.value?.showPicker()
  }
})

watch(showPassword, (newVal) => {
  if (newVal && input.value?.type === 'password') {
    input.value.setAttribute('type', 'text')
  } else if (!newVal && input.value?.type === 'text') {
    input.value.setAttribute('type', 'password')
  }
})
</script>

<template>
  <div
    ref="root"
    tabindex="-1"
    class="relative flex min-h-10 items-stretch overflow-clip rounded-lg bg-milk outline-2 outline-eggplant-700 elevation-1 elevation-lavender-400/20 thickness-3 thickness-lavender-400/20 focus-within:-translate-y-0.5 focus-within:elevation-2 hover:-translate-y-0.5 hover:elevation-2"
  >
    <!-- Icon -->
    <div
      v-if="$slots.icon"
      class="flex w-10 shrink-0 items-center justify-center border-r border-lavender-100 pb-0.5 *-[svg]:size-[1.25em]"
    >
      <slot name="icon" />
    </div>

    <div class="flex min-w-0 grow flex-col justify-center px-2.5 pb-2.5 pt-2">
      <!-- Label -->
      <div v-if="$slots.label" class="text-b3 !leading-3 text-lavender-800/60">
        <slot name="label" />
      </div>

      <!-- Input: Can be a text-based input element or a Select element -->
      <div
        ref="inputWrapper"
        class="*:strip *:text-b1 flex overflow-hidden *:flex-auto *:placeholder-lavender-300"
      >
        <slot />
      </div>
    </div>

    <!-- Show/Hide Password: Shown when a password input is passed -->
    <div
      v-if="type === 'password' || showPassword"
      class="relative flex shrink-0 items-center justify-center pr-3 text-lavender-400 *-[svg]:size-[1.25em]"
    >
      <PhEye v-if="showPassword" weight="duotone" />
      <PhEyeClosed v-else weight="duotone" />
      <input
        v-model="showPassword"
        type="checkbox"
        class="absolute h-full w-full opacity-0"
      />
    </div>

    <!-- Caret: Shown when a select element is passed -->
    <div
      v-if="type === 'select-one' || type === 'date'"
      class="flex w-8 shrink-0 items-center justify-center border-l border-lavender-200 bg-lavender-100/40 pb-0.5 text-lavender-400"
    >
      <PhCaretUpDown v-if="type === 'select-one'" weight="bold" />
      <PhCalendarDots v-if="type === 'date'" weight="duotone" />
    </div>
  </div>
</template>
