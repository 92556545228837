<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useAuth } from '@/plugins/auth'
import { Button, PromptPanel } from '@/components'

const router = useRouter()

const { logout: authLogout } = useAuth()

const logout = async () => {
  await authLogout()
  router.replace({ name: 'login' })
}
</script>

<template>
  <UseHead>
    <title>{{ $t('pages.logout.title') }}</title>
  </UseHead>

  <PromptPanel>
    <template #header>
      <h1>{{ $t('pages.logout.title') }}</h1>
    </template>

    <p>{{ $t('pages.logout.confirm') }}</p>

    <template #actions>
      <Button volume="loud" class="w-full" @click="logout">
        {{ $t('actions.logout') }}
      </Button>
    </template>
  </PromptPanel>
</template>
