<script setup lang="ts">
import { computed } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { PhCaretLeft, PhCaretRight } from '@phosphor-icons/vue'
import { Button } from '@/components'

const props = withDefaults(
  defineProps<{
    totalResults: number
    pageSize: number
    currentPage: number
    to?: (page: number) => RouteLocationRaw
    loading?: boolean
  }>(),
  {
    pageSize: 10,
    currentPage: 1,
    to: (page: number) => ({ query: { page } }),
  }
)

const totalPages = computed(() =>
  Math.ceil(props.totalResults / props.pageSize)
)
const canPrev = computed(() => props.currentPage > 1)
const canNext = computed(() => props.currentPage < totalPages.value)
</script>

<template>
  <div class="flex items-center gap-4">
    <Button
      size="sm"
      icon-only
      :to="to(currentPage - 1)"
      :disabled="loading || !canPrev"
    >
      <slot
        name="prev"
        :total-pages="totalPages"
        :i18n-label="$t('actions.previous')"
      >
        <PhCaretLeft weight="bold" />
      </slot>
    </Button>

    <div class="text-b1-num text-lavender-950/80">
      <slot name="pages" :total-pages="totalPages">
        {{ currentPage }}
        <span class="mx-0.5 text-lavender-700/40">/</span>
        {{ totalPages }}
      </slot>
    </div>

    <Button
      size="sm"
      icon-only
      :to="to(currentPage + 1)"
      :disabled="loading || !canNext"
    >
      <slot
        name="next"
        :total-pages="totalPages"
        :i18n-label="$t('actions.next')"
      >
        <PhCaretRight weight="bold" />
      </slot>
    </Button>
  </div>
</template>
