import * as Yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { FormSchema } from '@/components/DForm'
import { TextareaUpload } from '@/components/TextareaUpload'
import { Job } from '@@/types'

export const jobFormSchema: FormSchema<Job> = {
  rules: toTypedSchema(
    Yup.object({
      description: Yup.string(),
      rubricId: Yup.string().required(),
    })
  ),

  fields: [
    {
      name: 'description',
      label: 'Description',
      as: TextareaUpload,
      attrs: {
        rows: 10,
      },
    },
    {
      name: 'rubricId',
      label: 'Rubric',
      as: 'select',
    },

    {
      name: 'employeeResume1',
      label: 'Employee Resume 1',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },
    {
      name: 'employeeResume2',
      label: 'Employee Resume 2',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },
    {
      name: 'employeeResume3',
      label: 'Employee Resume 3',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },
    {
      name: 'employeeResume4',
      label: 'Employee Resume 4',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },
    {
      name: 'employeeResume5',
      label: 'Employee Resume 5',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },
  ],
}
