import * as Yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { FormSchema } from '@/components/DForm'
import { TextareaUpload } from '@/components/TextareaUpload'

// TODO: make a shared type with function
export interface Rubrik {
  jobDescription: string

  employeeResume1: string
  employeeResume2?: string
  employeeResume3?: string
  employeeResume4?: string
  employeeResume5?: string

  candidateResume: string
  candidateCoverLetter?: string

  rubrik: string
  format?: string
}

export const rubrikSchema: FormSchema<Rubrik> = {
  rules: toTypedSchema(
    Yup.object({
      jobDescription: Yup.string().required(),

      candidateResume: Yup.string().required(),
      candidateCoverLetter: Yup.string(),

      employeeResume1: Yup.string().required(),
      employeeResume2: Yup.string(),
      employeeResume3: Yup.string(),
      employeeResume4: Yup.string(),
      employeeResume5: Yup.string(),

      rubrik: Yup.string().required(),
      format: Yup.string(),
    })
  ),

  fields: [
    {
      name: 'jobDescription',
      label: 'Job Description',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },

    {
      name: 'candidateResume',
      label: 'Candidate Resume',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },
    {
      name: 'candidateCoverLetter',
      label: 'Candidate Cover Letter',
      badge: '(Optional)',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },

    {
      name: 'employeeResume1',
      label: 'Employee Resume 1',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },

    {
      name: 'employeeResume2',
      label: 'Employee Resume 2',
      badge: '(Optional)',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },
    {
      name: 'employeeResume3',
      label: 'Employee Resume 3',
      badge: '(Optional)',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },
    {
      name: 'employeeResume4',
      label: 'Employee Resume 4',
      badge: '(Optional)',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },
    {
      name: 'employeeResume5',
      label: 'Employee Resume 5',
      badge: '(Optional)',
      as: TextareaUpload,
      attrs: {
        rows: 5,
      },
    },

    {
      name: 'rubrik',
      label: 'Rubrik',
      as: TextareaUpload,
      attrs: {
        rows: 10,
      },
    },
    {
      name: 'format',
      label: 'Format',
      as: TextareaUpload,
      attrs: {
        rows: 10,
      },
    },
  ],
}
