import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from '@/plugins/auth'

import { AppLayout } from '@/layouts'

// import RubrikIndex from '@/pages/Index.vue'
// import Parse from '@/pages/Parse.vue'

import JobsIndex from '@/pages/jobs/Index.vue'
import JobsImport from '@/pages/jobs/Import.vue'
import JobsEdit from '@/pages/jobs/Edit.vue'

import CandidatesEdit from '@/pages/candidates/Edit.vue'

import RubricsIndex from '@/pages/rubrics/Index.vue'
import RubricsEdit from '@/pages/rubrics/Edit.vue'

import Login from '@/pages/Login.vue'
import Logout from '@/pages/Logout.vue'
import NotFound from '@/pages/404.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: { name: 'jobs' },
      name: 'home',
    },
    {
      path: '/jobs',
      meta: {
        layout: AppLayout,
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: JobsIndex,
          name: 'jobs',
        },
        {
          path: 'import',
          component: JobsImport,
          name: 'jobs-import',
        },
        {
          path: ':id',
          children: [
            {
              path: '',
              component: JobsEdit,
              name: 'jobs-edit',
            },
            {
              path: 'candidates/:candidateId',
              component: CandidatesEdit,
              name: 'candidates-edit',
            },
          ],
        },
      ],
    },

    {
      path: '/rubrics',
      meta: {
        layout: AppLayout,
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: RubricsIndex,
          name: 'rubrics',
        },
        {
          path: 'create',
          component: RubricsEdit,
          name: 'rubrics-create',
        },
        {
          path: ':id',
          component: RubricsEdit,
          name: 'rubrics-edit',
        },
      ],
    },

    {
      path: '/login',
      component: Login,
      name: 'login',
    },
    {
      path: '/logout',
      component: Logout,
      name: 'logout',
    },

    // catch all 404
    {
      path: '/:pathMatch(.*)*',
      component: NotFound,
      name: '404',
    },
  ],
})

router.beforeEach(async (to) => {
  const auth = getAuth()
  await auth.isReady

  // route guard, redirect to login if page requires auth and not logged in
  if (to.meta.requiresAuth && !auth.isLoggedIn.value) {
    return { name: 'login' }
  }
})

export default router
