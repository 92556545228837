<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { getApi, useApi } from '@/plugins/api'
import { syncRef, useOffsetPagination } from '@vueuse/core'
import { useRouteQuery } from '@vueuse/router'
import {
  Button,
  DateTimeFormat,
  DetailList,
  Empty,
  Pagination,
  Panel,
  Loading,
} from '@/components'
import { PaginatedResponse, Job } from '@@/types'
import router from '@/router'
import PageLayout from '@/components/PageLayout/PageLayout.vue'
import { PhDownloadSimple } from '@phosphor-icons/vue'

const api = getApi()

const total = ref(0)

const { currentPage, currentPageSize } = useOffsetPagination({ total })

const queryPage = useRouteQuery('page', 1, { transform: Number })

syncRef(queryPage, currentPage)

const { data, isLoading, execute } = useApi<PaginatedResponse<Job>>(
  '/jobs/import',
  {
    params: { page: currentPage.value },
  },
  {
    immediate: false,
    onSuccess: (res) => {
      total.value = res.totalCount
      currentPageSize.value = res.pageSize
    },
  }
)

watch(
  currentPage,
  (val) => {
    execute({
      params: { page: val },
    })
  },
  { immediate: true }
)

const jobs = computed(() => data.value?.results ?? undefined)

const importJob = async (id: string | number) => {
  const res = await api.post<Job[]>(`/jobs/${id}/import`)
  router.push({ name: 'jobs', query: { highlight: res.data[0].id } })
}
</script>

<template>
  <useHead>
    <title>{{ $t('pages.jobsImport.title') }}</title>
  </useHead>

  <PageLayout>
    <template #sticky-header>
      <PageHeader
        :title="$t('pages.jobsImport.title')"
        :breadcrumbs="[{ text: $t('nav.jobs'), to: { name: 'jobs' } }]"
      />
    </template>

    <Empty v-if="!jobs && isLoading" :title="$t('messages.loading')" size="xl">
      <Loading />
    </Empty>

    <Empty
      v-else-if="!jobs && !isLoading"
      :title="$t('messages.noModel', { model: $t('models.jobs') })"
      size="xl"
      muted
    />

    <section v-else>
      <ul class="mb-5 space-y-2">
        <Panel
          v-for="job in jobs"
          :key="job.remoteId"
          as="li"
          padding="lg"
          header-class="flex items-center gap-5"
          :class="{ 'opacity-50': job.importedAt }"
        >
          <template #header>
            <div class="mr-auto">
              <RouterLink
                :to="{ name: 'jobs-edit', params: { id: job.id } }"
                class="font-semibold"
              >
                {{ job.name }}
              </RouterLink>
            </div>

            <Button
              v-if="!job.importedAt"
              size="sm"
              @click="importJob(job.remoteId)"
            >
              <PhDownloadSimple weight="regular" />
              {{ $t('actions.import') }}
            </Button>
          </template>

          <template #default>
            <DetailList
              :entries="job"
              :fields="['companyName', 'salary', 'createdAt', 'importedAt']"
              i18n-prefix="fields.job"
            >
              <template #createdAt-value="{ value }">
                <DateTimeFormat v-if="value" :date="value" />
              </template>

              <template #importedAt-value="{ value }">
                <DateTimeFormat v-if="value" :date="value" />
              </template>
            </DetailList>
          </template>
        </Panel>
      </ul>
    </section>

    <template #sticky-footer>
      <Pagination
        :total-results="total"
        :current-page="currentPage"
        :page-size="currentPageSize"
        :loading="isLoading"
      />
    </template>
  </PageLayout>
</template>
