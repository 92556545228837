<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useApi } from '@/plugins/api'
import { syncRef, useOffsetPagination } from '@vueuse/core'
import { useRouteQuery } from '@vueuse/router'
import { PhDownloadSimple, PhPencilSimpleLine } from '@phosphor-icons/vue'
import {
  Button,
  DateTimeFormat,
  DetailList,
  Empty,
  MenuItem,
  Pagination,
  Panel,
  Loading,
} from '@/components'
import { PaginatedResponse, Job } from '@@/types'

const route = useRoute()
const highlight = route.query.highlight

const total = ref(0)

const { currentPage, currentPageSize } = useOffsetPagination({ total })

const queryPage = useRouteQuery('page', 1, { transform: Number })

syncRef(queryPage, currentPage)

const { data, isLoading, execute } = useApi<PaginatedResponse<Job>>(
  '/jobs',
  {
    params: { page: currentPage.value },
  },
  {
    immediate: false,
    onSuccess: async (res) => {
      total.value = res.totalCount
      currentPageSize.value = res.pageSize

      // scroll to highlighted job
      if (highlight) {
        await nextTick()

        const el = document.getElementById(highlight as string)
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    },
  }
)

watch(
  currentPage,
  (val) => {
    execute({
      params: { page: val },
    })
  },
  { immediate: true }
)

const jobs = computed(() => data.value?.results ?? undefined)
</script>

<template>
  <useHead>
    <title>{{ $t('pages.jobs.title') }}</title>
  </useHead>

  <PageLayout>
    <template #sticky-header>
      <PageHeader :title="$t('pages.jobs.title')">
        <template #actions>
          <Button volume="loud">
            <PhDownloadSimple weight="bold" />
            {{ $t('actions.importFrom') }}

            <template #menu>
              <MenuItem :to="{ name: 'jobs-import' }">Loxo</MenuItem>
              <MenuItem disabled>Greenhouse</MenuItem>
              <MenuItem disabled>Ashby</MenuItem>
            </template>
          </Button>
        </template>
      </PageHeader>
    </template>

    <Empty v-if="!jobs && isLoading" :title="$t('messages.loading')" size="xl">
      <Loading />
    </Empty>

    <Empty
      v-else-if="!jobs && !isLoading"
      :title="$t('messages.noModel', { model: $t('models.jobs') })"
      size="xl"
      muted
    />

    <section v-else>
      <ul class="mb-5 space-y-2">
        <Panel
          v-for="job in jobs"
          :id="job.id"
          :key="job.id"
          as="li"
          padding="lg"
          header-class="flex items-center gap-5"
          :class="{ 'outline outline-primary-500': highlight === job.id }"
        >
          <template #header>
            <div class="mr-auto">
              <RouterLink
                :to="{ name: 'jobs-edit', params: { id: job.id } }"
                class="font-semibold"
              >
                {{ job.name }}
              </RouterLink>
            </div>

            <div class="space-x-2">
              <Button
                :to="{ name: 'jobs-edit', params: { id: job.id } }"
                size="sm"
              >
                <PhPencilSimpleLine weight="duotone" />
                {{ $t('actions.edit') }}
              </Button>
            </div>
          </template>

          <template #default>
            <DetailList
              :entries="job"
              :fields="['companyName', 'salary', 'createdAt', 'importedAt']"
              i18n-prefix="fields.job"
            >
              <template #createdAt-value="{ value }">
                <DateTimeFormat v-if="value" :date="value" />
              </template>

              <template #importedAt-value="{ value }">
                <DateTimeFormat v-if="value" :date="value" />
              </template>
            </DetailList>
          </template>
        </Panel>
      </ul>
    </section>

    <template #sticky-footer>
      <Pagination
        :total-results="total"
        :current-page="currentPage"
        :page-size="currentPageSize"
        :loading="isLoading"
      />
    </template>
  </PageLayout>
</template>
