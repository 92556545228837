import { ref, onMounted } from 'vue'
import type { Ref } from 'vue'

type FormInput = HTMLInputElement | HTMLSelectElement
type MaybeFormInput = FormInput | undefined
type MaybeString = string | undefined

const supportedTags = ['INPUT', 'SELECT']
const supportedTypes = ['text', 'email', 'password', 'date', 'select-one']

export function useFirstInput(parent: Ref<Element>) {
  const input = ref<MaybeFormInput>()
  const type = ref<MaybeString>()

  onMounted(() => {
    if (parent.value) {
      input.value = Array.from(parent.value.children).find(
        (child) =>
          supportedTags.includes(child.tagName) &&
          supportedTypes.includes((child as FormInput).type)
      ) as MaybeFormInput
    }

    if (input.value) {
      type.value = input.value.type
    }
  })

  return { input, type }
}
